<template>
	<div>
		<pui-datatable
			v-if="!masterDetail"
			:modelName="modelName"
			:externalFilter="filterByPortAuthorityId"
			:modelColumnDefs="modelColumnDefs"
			:actions="actions"
		></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="filterByPortAuthorityId"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:actions="actions"
			:modalDialog="modalDialog"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<stopmodals :modelName="modelName"></stopmodals>
		<customfields-modals></customfields-modals>
	</div>
</template>

<script>
import StopActions from './StopActions.js';
import StopModals from './StopModals';

import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';

export default {
	name: 'stopgrid',
	components: { stopmodals: StopModals },
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'stop',
			actions: StopActions.gridactions,
			modelColumnDefs: {
				status: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				},
				isautoata: {
					render: (data, type, row) => {
						return row.isautoata ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				isautoatd: {
					render: (data, type, row) => {
						return row.isautoatd ? `<i class="fas fa-check state-check"></i>` : '';
					}
				},
				notifications: {
					render: (data, type, row) => {
						let notification = '';
						if (row.delayeta || row.delayetd) {
							let delayeDescription = '';
							if (row.delayeta && row.delayetd) {
								delayeDescription = this.$t('header.stop.delayedetaetdNotification');
							} else if (row.delayeta) {
								delayeDescription = this.$t('header.stop.delayedetaNotification');
							} else {
								delayeDescription = this.$t('header.stop.delayedetdNotification');
							}
							notification += `<i title='${delayeDescription}' style="color: red; padding-right: 10px" class="fas fa-clock"></i>`;
						}
						if (row.notifychangesac) {
							let notifychangesacDescription = this.$t('header.stop.notifychangesacNotification');
							notification += `<i title='${notifychangesacDescription}' style="color: red; padding-right: 10px" class="fas fa-exclamation-circle"></i>`;
						}

						return notification;
					}
				}
			}
		};
	},
	computed: {
		filterByPortAuthorityId() {
			console.log(this.externalFilter);

			let userQuays = [];
			let userQuaysId = [];
			userQuays = this.$store.getters.getUserBerths;
			userQuaysId = userQuays ? userQuays.map((quay) => quay.id) : null;
			const workingPortAuthorityId = window.localStorage.getItem('workingPortAuthorityId');
			return {
				groups: [this.externalFilter],
				groupOp: 'and',
				rules: [
					{ field: 'port_authority_id', op: 'eq', data: workingPortAuthorityId },
					{ field: 'location_id', op: 'in', data: userQuaysId },
					{ field: 'isanchorage', op: 'eq', data: false }
				]
			};
		}
	}
};
</script>
